import React from "react"

import Layout from "../components/Layouts/secondaryPage"

import DeliveryRedesign from "../components/DeliveryRedesign"
import { NewHeader } from "../components/Headers/NewHeader"

export default function DeliveryPage() {
  return (
    <div className="bg-wrapper">
      <Layout>
        <NewHeader withButton={false} noRightSection noShadow={false} />
        <DeliveryRedesign />
      </Layout>
    </div>
  )
}
