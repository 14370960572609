import React from "react"

import RadioGroupBtn from "@ecom/ui/components/FormLanding/fields/RadioGroupBtn"

import * as styles from "./timeGroupBtn.module.scss"

type Props = {
  label: string
  name: string
  items: string[]
}

const radioBtnClasses = { box: styles.box, label: styles.label, input: styles.input }

export default function TimeGroupBtn({ label, name, items }: Props) {
  const options = items.map((value) => ({
    value: value.slice(-5),
    label: value,
  }))

  return (
    <section>
      <span className={styles.deliveryTime}>{label}</span>
      <RadioGroupBtn
        className={styles.radioGroupBtnContainer}
        name={name}
        items={options}
        defaultValue={options[0].value}
        radioBtnClasses={radioBtnClasses}
        classes={{ container: styles.container }}
      />
    </section>
  )
}
