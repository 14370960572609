import isValid from "date-fns/isValid"

export function getTomorrowDate(today = new Date()) {
  today.setHours(0, 0, 0, 0)

  const tomorrow = new Date(today)

  tomorrow.setDate(tomorrow.getDate() + 1)

  return tomorrow
}

export function pickupDateValidator(date: Date) {
  if (!isValid(date)) {
    return "Введите корректную дату"
  }

  const today = new Date()
  const tomorrow = getTomorrowDate(today)

  if (date < tomorrow) {
    return "Не раньше завтрашнего дня"
  }

  today.setHours(23)
  today.setDate(today.getDate() + 7)

  if (date > today) {
    return "Не позже 7 дней"
  }

  return ""
}
