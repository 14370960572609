import React from "react"

import * as styles from "./addressLabel.module.scss"

type Props = {
  label: string
  address?: string
}

export default function AddressLabel({ label, address }: Props) {
  return (
    <>
      <div className={styles.label}>{label}</div>
      {address && <div className={styles.address}>{address}</div>}
    </>
  )
}
