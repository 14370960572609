import React from "react"

export const ThirdStepIcon = () => (
  <>
    <svg
      width="178"
      height="24"
      viewBox="0 0 178 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.04098 12C4.04098 7.58172 7.65936 4 12.1229 4C16.5864 4 20.2048 7.58172 20.2048 12C20.2048 16.4183 16.5864 20 12.1229 20C7.65936 20 4.04098 16.4183 4.04098 12ZM16.835 8.75259C17.252 9.11951 17.2895 9.75158 16.9189 10.1644L11.5309 16.1644C11.3392 16.3779 11.0644 16.5 10.7759 16.5C10.4873 16.5 10.2125 16.3779 10.0208 16.1644L7.32686 13.1644C6.95619 12.7516 6.99375 12.1195 7.41076 11.7526C7.82777 11.3857 8.46631 11.4229 8.83698 11.8356L10.7759 13.9948L15.4087 8.83564C15.7794 8.42285 16.418 8.38567 16.835 8.75259Z"
        fill="#76BC21"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.04098 12C4.04098 7.58172 7.65936 4 12.1229 4C16.5864 4 20.2048 7.58172 20.2048 12C20.2048 16.4183 16.5864 20 12.1229 20C7.65936 20 4.04098 16.4183 4.04098 12ZM12.1229 2C6.54349 2 2.02051 6.47715 2.02051 12C2.02051 17.5229 6.54349 22 12.1229 22C17.7022 22 22.2252 17.5229 22.2252 12C22.2252 6.47715 17.7022 2 12.1229 2Z"
        fill="#76BC21"
      />
      <line x1="21.8105" y1="12.7" x2="80.0001" y2="12.7" stroke="#76BC21" />
      <g clipPath="url(#clip0_2249_1995)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M158.4 12.3999C158.4 7.98162 161.982 4.3999 166.4 4.3999C170.819 4.3999 174.4 7.98162 174.4 12.3999C174.4 16.8182 170.819 20.3999 166.4 20.3999C161.982 20.3999 158.4 16.8182 158.4 12.3999ZM171.065 9.15249C171.478 9.51941 171.515 10.1515 171.148 10.5643L165.814 16.5643C165.625 16.7778 165.353 16.8999 165.067 16.8999C164.781 16.8999 164.509 16.7778 164.32 16.5643L161.653 13.5643C161.286 13.1515 161.323 12.5194 161.736 12.1525C162.149 11.7856 162.781 11.8228 163.148 12.2355L165.067 14.3947L169.653 9.23554C170.02 8.82276 170.652 8.78557 171.065 9.15249Z"
          fill="#76BC21"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M158.4 12.3999C158.4 7.98162 161.982 4.3999 166.4 4.3999C170.819 4.3999 174.4 7.98162 174.4 12.3999C174.4 16.8182 170.819 20.3999 166.4 20.3999C161.982 20.3999 158.4 16.8182 158.4 12.3999ZM166.4 2.3999C160.878 2.3999 156.4 6.87705 156.4 12.3999C156.4 17.9228 160.878 22.3999 166.4 22.3999C171.923 22.3999 176.4 17.9228 176.4 12.3999C176.4 6.87705 171.923 2.3999 166.4 2.3999Z"
          fill="#76BC21"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.041 12C81.041 7.58172 84.6594 4 89.1229 4C93.5864 4 97.2048 7.58172 97.2048 12C97.2048 16.4183 93.5864 20 89.1229 20C84.6594 20 81.041 16.4183 81.041 12ZM93.835 8.75259C94.252 9.11951 94.2895 9.75158 93.9189 10.1644L88.5309 16.1644C88.3392 16.3779 88.0644 16.5 87.7759 16.5C87.4873 16.5 87.2125 16.3779 87.0208 16.1644L84.3269 13.1644C83.9562 12.7516 83.9938 12.1195 84.4108 11.7526C84.8278 11.3857 85.4663 11.4229 85.837 11.8356L87.7759 13.9948L92.4087 8.83564C92.7794 8.42285 93.418 8.38567 93.835 8.75259Z"
        fill="#76BC21"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.041 12C81.041 7.58172 84.6594 4 89.1229 4C93.5864 4 97.2048 7.58172 97.2048 12C97.2048 16.4183 93.5864 20 89.1229 20C84.6594 20 81.041 16.4183 81.041 12ZM89.1229 2C83.5435 2 79.0205 6.47715 79.0205 12C79.0205 17.5229 83.5435 22 89.1229 22C94.7022 22 99.2252 17.5229 99.2252 12C99.2252 6.47715 94.7022 2 89.1229 2Z"
        fill="#76BC21"
      />
      <line x1="98.8105" y1="12.7" x2="157" y2="12.7" stroke="#76BC21" />
      <defs>
        <clipPath id="clip0_2249_1995">
          <rect width="24" height="24" fill="white" transform="translate(154)" />
        </clipPath>
      </defs>
    </svg>
  </>
)
