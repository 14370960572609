import React, { useState, useEffect, useRef, useCallback } from "react"
import { navigate } from "gatsby"

import clsx from "clsx"

import DadataAddressFlat from "@ecom/ui/components/FormLanding/fields/DadataFields/DadataAddressFlat"
import RadioGroupField from "@ecom/ui/components/FormLanding/fields/RadioGroupField"
import emailValidator from "@ecom/ui/components/FormLanding/validators/emailValidator"
import EmailField from "@ecom/ui/components/FormLanding/fields/EmailField"
import PhoneField from "@ecom/ui/components/FormLanding/fields/PhoneField"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import { useLocation } from "@gatsbyjs/reach-router"

import { Box, useMediaQuery } from "@material-ui/core"
import FormControl from "../FormControl"
import DateTimePicker from "../DateTimePicker"
import AddressLabel from "./AddressLabel"

import {
  formatValuesToRequest,
  fetchavAvailableDeliveryTypes,
  fetchDeliveryTimeIntervals,
  formatTimeIntervals,
} from "./helpers"
import disableWebvisor from "../../utils/disableWebvisor"
import { getManyIDBValues, setIDBValue } from "../../utils/idbUtils"
import { comparePhoneNumbers } from "../../helpers/validators"

import type { FieldData } from "../../interfaces/fieldData"
import { IDadataAddress } from "../../interfaces/dadata"
import type { DeliveryFormData } from "./types"

import * as styles from "./deliveryRedesign.module.scss"
import { LongAppWrapper } from "../LongAppWrapper"
import { GuardSecuritySvg } from "../../images/svg/Icon"
import { ThirdStepIcon } from "../../images/svg/ThirdStep"
import { sendRequest } from "../../helpers/sendRequest"
import { events } from "../../helpers/events"
import { HalvaResponse } from "../../interfaces/responseStatus"

// REGISTRATION_ADDRESS - 420021, Респ Татарстан, г Казань, ул Габдуллы Тукая, д 1 kladrID: "1600000100000"

const LABELS = {
  registration_address: "Адрес регистрации",
  life_address: "Адрес проживания",
  work_address: "На работу",
}

const PUBLIC_ADDRESS = {
  value: "public_address",
  label: <AddressLabel label="Другой адрес" />,
}

export default function Delivery() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isAddressCustom, setIsAddressCustom] = useState(true)
  const [requestId, setRequestId] = useState("")
  const [userPhone, setUserPhone] = useState("")
  const tablet = useMediaQuery("(min-width: 960px)")
  const [addresses, setAddresses] = useState<Record<string, string>>({})
  const [timeStamps, setTimeStamps] = useState<string[]>([])
  const location = useLocation()

  const radioGroupItems = Object.entries(addresses)
    .filter(([, value]) => value !== "")
    .map(([key, value]) => ({
      value: key,
      label: <AddressLabel label={LABELS[key as keyof typeof LABELS]} address={value} />,
    }))
  // всегда показываем Другой адрес
  radioGroupItems.push(PUBLIC_ADDRESS)

  const refForm = useRef<any>(null)

  const onChangeFields = {
    deliveryType: ({ value }: FieldData<string>) => {
      const isRequired = value === "public_address"
      refForm.current.setField("deliveryAddress", { isRequired })
      setIsAddressCustom(isRequired)
    },
    deliveryAddress: (fieldData: FieldData<IDadataAddress>) => {
      const { value, error, initialHelperText } = fieldData

      if (error) return

      const { city_kladr_id, settlement_kladr_id } = value.dadataValue?.data || {}

      fetchavAvailableDeliveryTypes({ public: city_kladr_id || settlement_kladr_id })
        .then((resData) => {
          switch (resData?.PUBLIC_ADDRESS) {
            case true:
              refForm.current.setField("deliveryAddress", {
                error: null,
                helperText: initialHelperText,
              })
              break
            case false:
              refForm.current.setField("deliveryAddress", {
                value: "",
                error: { addressDadata: "В выбранный населённый пункт доставка не осуществляется" },
                helperText: {
                  addressDadata: "В выбранный населённый пункт доставка не осуществляется",
                },
              })
              break
            default:
              throw new Error()
          }
        })
        .catch(() => {
          refForm.current.setField("deliveryAddress", {
            value: "",
            error: { addressDadata: "Заполните адрес доставки" },
            helperText: {
              addressDadata: "Заполните адрес доставки",
            },
          })
        })
    },
  }

  // отключение записи ПД в вебвизоре
  const ref = useCallback((node: any) => {
    disableWebvisor(node)
  }, [])

  useEffect(() => {
    getManyIDBValues(["phone", "requestId"]).then(([userName, id]) => {
      if (userName) {
        setUserPhone(userName)
      }
      if (id) {
        setRequestId(id)
      }
    })
  }, [])

  useEffect(() => {
    fetchDeliveryTimeIntervals()
      .then((tss) => {
        const newTimeStamps = tss.map(({ beginInterval, endInterval }) =>
          formatTimeIntervals(beginInterval, endInterval)
        )
        setTimeStamps(newTimeStamps)
      })
      .catch((e) => console.error(e))
  }, [])

  useEffect(() => {
    getManyIDBValues(["lifeAddress", "registrationAddress", "workAddress"]).then(
      ([life, registration, work]: { kladrID: string; address: string }[]) => {
        fetchavAvailableDeliveryTypes({
          work: work?.kladrID, // || "1600000100000",
          registration: registration?.kladrID, // || "1600000100000",
          home: life?.kladrID, // || "1600000100000",
        })
          .then((resData) => {
            setAddresses({
              registration_address: resData.REGISTRATION_ADDRESS
                ? registration?.address // || "420021, Респ Татарстан, г Казань, ул Габдуллы Тукая, д 1"
                : "",
              life_address: resData.HOME_ADDRESS
                ? life?.address // || "420021, Респ Татарстан, г Казань, ул Габдуллы Тукая, д 1"
                : "",
              work_address: resData.WORK_ADDRESS
                ? work?.address // || "420021, Респ Татарстан, г Казань, ул Габдуллы Тукая, д 1"
                : "",
            })
          })
          .catch(() => {
            setAddresses({})
          })
      }
    )
  }, [])

  // по-умолчанию доставка На работу, если доступно
  useEffect(() => {
    if (addresses.work_address) {
      refForm.current.setField("deliveryType", { value: "work_address" })
      refForm.current.setField("deliveryAddress", { isRequired: false })
      setIsAddressCustom(false)
    }
  }, [addresses.work_address])

  function handleSubmit(values: DeliveryFormData) {
    pushToDataLayer(events.GAFormEvent.continue)
    setIsSubmitting(true)
    const valuesToRequest = formatValuesToRequest(values)

    if (valuesToRequest.deliveryType !== PUBLIC_ADDRESS.value) {
      valuesToRequest.deliveryAddress = addresses[valuesToRequest.deliveryType]
    }

    sendRequest(`/v2/app/public/halva/${requestId}`, { ...valuesToRequest }, true)
      .then(({ status, apiId }: HalvaResponse) => {
        switch (status) {
          case "reject":
            navigate("/error-reject")
            pushToDataLayer(events.applicationSend.reject)
            return
          case "error":
            throw Error(status)
          default:
            break
        }

        setIDBValue("requestId", apiId)
      })
      .then(() => {
        navigate(location.state.thanksPage ? location.state.thanksPage : "/long-form-success/")
      })
      .catch(() => {
        pushToDataLayer(events.applicationSend.error)
        pushToDataLayer(events.GAFormEvent.error)
        navigate("/error-technical/")
      })
  }

  return (
    <LongAppWrapper
      name="deliveryForm"
      handleSubmit={handleSubmit}
      onChangeFields={onChangeFields}
      refForm={refForm}
      isDisabled={isSubmitting}
      stepIcon={<ThirdStepIcon />}
      withSteps
      btnText="Заказать карту"
      variant="custom"
      title={
        <h2 className={clsx(styles.formHead, styles.main)} ref={ref}>
          Укажите адрес доставки
        </h2>
      }
    >
      <div className={styles.longContainerFluid}>
        <div className={styles.wrapGuaranteeSecurity}>
          <GuardSecuritySvg color="#76BC21" />
          <p>Мы гарантируем безопасность и сохранность ваших данных</p>
        </div>
        <RadioGroupField
          name="deliveryType"
          items={radioGroupItems}
          defaultValue={PUBLIC_ADDRESS.value}
          row={false}
          classes={{
            formControlLabel: styles.formControlLabel,
          }}
        />
        {isAddressCustom && (
          <div className={styles.customAddress}>
            <DadataAddressFlat name="deliveryAddress" />
          </div>
        )}
        <h2 className={styles.formHead}>Дата и время доставки</h2>
        {timeStamps.length > 0 && (
          <FormControl className={styles.dataPicker}>
            <DateTimePicker
              okLabel=""
              cancelLabel=""
              variant="inline"
              timeLabel="Время доставки"
              timeItems={timeStamps}
            />
          </FormControl>
        )}
        <h2 className={styles.formHead}>Дополнительная информация</h2>

        <Box className={styles.fieldWrapper}>
          <FormControl className={styles.shortField}>
            <PhoneField
              name="secondPhone"
              defaultValue=""
              label={tablet ? "Дополнительный номер телефона" : "Дополнительный телефон"}
              placeholder="+7 (000) 000-00-00"
              validate={comparePhoneNumbers(userPhone)}
              helperText="Оставьте дополнительный контакт, если основной телефон будет недоступен"
            />
          </FormControl>
          <FormControl className={styles.shortField}>
            <EmailField
              name="email"
              label="Email"
              placeholder="example@example.ru"
              validate={emailValidator}
              inputProps={{
                maxLength: 50,
              }}
            />
          </FormControl>
        </Box>
      </div>
    </LongAppWrapper>
  )
}
