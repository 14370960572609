import React from "react"

import addDays from "date-fns/addDays"

import PickersProvider from "@ecom/ui/components/PickersProvider"
import DatePicker from "@ecom/ui/components/FormLanding/fields/DatePicker"

import FormControl from "../FormControl"
import TimeGroupBtn from "./TimeGroupBtn"

import { pickupDateValidator, getTomorrowDate } from "./helpers"

const MAX_DATE = addDays(new Date(), 7)

type Props = {
  timeLabel: string
  timeItems: string[]
  okLabel?: string
  cancelLabel?: string
  variant?: "inline" | ""
}

export default function DateTimePicker({
  timeLabel,
  timeItems,
  okLabel = "Выбрать",
  cancelLabel = "Отмена",
  variant = "",
}: Props) {
  return (
    <>
      <PickersProvider>
        <FormControl>
          <DatePicker
            name="deliveryDate"
            validate={pickupDateValidator}
            minDate={getTomorrowDate()}
            maxDate={MAX_DATE}
            okLabel={okLabel}
            cancelLabel={cancelLabel}
            variant={variant}
          />
        </FormControl>
      </PickersProvider>

      <FormControl>
        <TimeGroupBtn label={timeLabel} name="deliveryTime" items={timeItems} />
      </FormControl>
    </>
  )
}
