import format from "date-fns/format"
import formatToRequest from "@ecom/ui/helpers/formatToRequest"
import sendRequest from "@ecom/ui/utils/sendRequest"

import type { DeliveryFormData, DeliveryFormDataFormatted } from "./types"

import { getAddress } from "../../helpers/getAddress"

const fieldsType = {
  deliveryAddress: "dadataAddressFlat",
}

export function formatValuesToRequest(values: DeliveryFormData): DeliveryFormDataFormatted {
  const valuesToRequest = formatToRequest(values, fieldsType)

  if (valuesToRequest.deliveryAddress) {
    valuesToRequest.deliveryAddress = getAddress(valuesToRequest.deliveryAddress)
  }

  if (values.additionalProductTerm) {
    valuesToRequest.additionalProductTerm = parseInt(values.additionalProductTerm, 10)
  }

  if (values.deliveryDate) {
    valuesToRequest.deliveryDate = format(values.deliveryDate, "yyyy-MM-dd")
  }

  return valuesToRequest
}

interface RequestData {
  work?: string
  registration?: string
  home?: string
  public?: string
}

interface ResponseData {
  WORK_ADDRESS: boolean
  REGISTRATION_ADDRESS: boolean
  HOME_ADDRESS: boolean
  PUBLIC_ADDRESS: boolean
}

export function fetchavAvailableDeliveryTypes(reqData: RequestData): Promise<ResponseData> {
  return sendRequest(`${process.env.GATSBY_APP_API_URL}/v2/app/public/halva/delivery`, reqData)
}

type DeliveryTimeIntervalsResponse = Array<{
  beginInterval: string
  endInterval: string
}>

export function fetchDeliveryTimeIntervals(): Promise<DeliveryTimeIntervalsResponse> {
  return fetch(`${process.env.GATSBY_APP_API_URL}/constants/delivery/intervals`).then((res) => {
    if (!res.ok) throw new Error("fetchDeliveryTimeIntervals Error")
    return res.json()
  })
}

export function formatTimeIntervals(interval1: string, interval2: string) {
  return `${interval1.substring(0, 5)} - ${interval2.substring(0, 5)}`
}
